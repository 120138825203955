const makePlaceholdersDisappear = () => {
  const inputs = document.querySelectorAll('input, textarea');

  inputs.forEach(input => {
    const placeholder = input.getAttribute('placeholder');

    input.addEventListener('focus', () => {
      input.setAttribute('placeholder', '');
    });

    input.addEventListener('blur', () => {
      if (input.value === '') {
        setTimeout(() => {
          input.setAttribute('placeholder', placeholder);
        }, 500);
      }
    });
  });
}

export { makePlaceholdersDisappear };