const infosPratiquesOnglets = () => {
    const onglets = document.querySelectorAll('.onglet');
    const contenus = document.querySelectorAll('.contenu');
    let index = 0;
    onglets.forEach(onglet => {
        onglet.addEventListener('click', () => {
            index = onglet.getAttribute('data-anim')
            if (onglet.classList.contains('activeOnglet')) {
                return;
            } else {
                onglet.classList.add('activeOnglet');
                onglets.forEach(onglet => {
                    if (onglet.getAttribute('data-anim') != index) {
                        onglet.classList.remove('activeOnglet');
                    } else {
                        return;
                    }
                });

                contenus.forEach(contenu => {
                    if (contenu.getAttribute('data-anim') == index) {
                        contenu.classList.add('activeContenu');
                    } else {
                        contenu.classList.remove('activeContenu');
                    }
                });
            }
        })
    });

};

const infosFormatMobile = () => {
    const btnEvenement = document.querySelector('#mobile-evenement');
    const btnSalon = document.querySelector('#mobile-salon');
    const formatMobileMenu = document.querySelector('.infos-mobile-menu');
    const formatMobileEvenement = document.querySelector('.infos-mobile-evenement');
    const formatMobileSalon = document.querySelector('.infos-mobile-salon');
    const btnRetourMobileMenu1 = document.querySelector('.retour-mobile-menu1');
    const btnRetourMobileMenu2 = document.querySelector('.retour-mobile-menu2');

    if (btnEvenement) {
        btnEvenement.addEventListener('click', () => {
            formatMobileMenu.classList.remove('infos-mobile-active');
            formatMobileMenu.classList.add('infos-mobile-inactive');
            formatMobileEvenement.classList.remove('infos-mobile-inactive');
            formatMobileEvenement.classList.add('infos-mobile-active');
        });

        btnRetourMobileMenu1.addEventListener('click', () => {
            formatMobileEvenement.classList.add('infos-mobile-inactive');
            formatMobileEvenement.classList.remove('infos-mobile-active');
            formatMobileMenu.classList.add('infos-mobile-active');
            formatMobileMenu.classList.remove('infos-mobile-inactive');
        });
    }

    if (btnSalon) {
        btnSalon.addEventListener('click', () => {
            formatMobileMenu.classList.remove('infos-mobile-active');
            formatMobileMenu.classList.add('infos-mobile-inactive');
            formatMobileSalon.classList.remove('infos-mobile-inactive');
            formatMobileSalon.classList.add('infos-mobile-active');
        });

        btnRetourMobileMenu2.addEventListener('click', () => {
            formatMobileSalon.classList.add('infos-mobile-inactive');
            formatMobileSalon.classList.remove('infos-mobile-active');
            formatMobileMenu.classList.add('infos-mobile-active');
            formatMobileMenu.classList.remove('infos-mobile-inactive');
        });
    }


};


export { infosPratiquesOnglets };
export { infosFormatMobile };