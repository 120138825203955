const modalAction = () => {

  // // Première modal infos pratiques carte
  // const modalBtn1 = document.querySelector('.modal-infos-btn-1');
  // const modalBg1 = document.querySelector('.modal-bg-1');
  // const modalClose1 = document.querySelector('.modal-close-1');

  // if (modalBtn1) {
  //   modalBtn1.addEventListener('click', () => {
  //     modalBg1.classList.add('modal-bg-active');
  //   });

  //   modalClose1.addEventListener('click', () => {
  //     modalBg1.classList.remove('modal-bg-active');
  //   });
  // }

  // // Deuxième modal infos pratiques plan

  // const modalBtn2 = document.querySelector('.modal-infos-btn-2');
  // const modalBg2 = document.querySelector('.modal-bg-2');
  // const modalClose2 = document.querySelector('.modal-close-2');

  // if (modalBtn2) {
  //   modalBtn2.addEventListener('click', () => {
  //     modalBg2.classList.add('modal-bg-active');
  //   });

  //   modalClose2.addEventListener('click', () => {
  //     modalBg2.classList.remove('modal-bg-active');
  //   });
  // }

  // Troisième modal infos pratiques moments forts

  // const modalBtn3 = document.querySelector('.modal-infos-btn-3');
  // const modalBg3 = document.querySelector('.modal-bg-3');
  // const modalClose3 = document.querySelector('.modal-close-3');

  // if (modalBtn3) {
  //   modalBtn3.addEventListener('click', () => {
  //     modalBg3.classList.add('modal-bg-active');
  //   });

  //   modalClose3.addEventListener('click', () => {
  //     modalBg3.classList.remove('modal-bg-active');
  //   });
  // }

  // Quatrième modal plan des stands

  window.showStandsModal = () => {
    var modalBtn4 = document.querySelector('.modal-stands-btn');
    var modalBg4 = document.querySelector('.modal-bg-4');
    var modalClose4 = document.querySelector('.modal-close-4');

    if (modalBtn4) {
      modalBtn4.addEventListener('click', () => {
        document.querySelector("body").style.overflow = 'hidden';
        document.querySelector(".navbar").classList.add("d-none");
        modalBg4.classList.add('modal-bg-active');

        // Script to scroll down the page when arrow is clicked
        const arrowDown = document.querySelector(".arrow-down");
        const modal = document.querySelector(".modal-bg-active");

        if (arrowDown) {
          arrowDown.addEventListener("click", () => {
            modal.scrollTop += 1000;
          });
        }
      });

      modalClose4.addEventListener('click', () => {
        document.querySelector("body").style.overflow = 'scroll';
        document.querySelector(".navbar").classList.remove("d-none");
        modalBg4.classList.remove('modal-bg-active');
      });
    }
  }
}

export { modalAction };
