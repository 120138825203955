const navbarAction = () => {
    const btnOpenNavbar = document.querySelector('.open-navbar');
    const menuNavbar = document.querySelector('.nav-mobile');
    const btnCloseNavbar = document.querySelector('.close-navbar');

    if (btnOpenNavbar) {
        btnOpenNavbar.addEventListener('click', () => {
            menuNavbar.classList.remove('navbar-mobile-menu-content');
            menuNavbar.classList.add('navbar-mobile-menu-content-active');
            btnOpenNavbar.classList.remove('navbar-mobile-menu-active');
            btnOpenNavbar.classList.add('navbar-mobile-menu');
            btnCloseNavbar.classList.remove('navbar-mobile-menu');
            btnCloseNavbar.classList.add('navbar-mobile-menu-active');
        });

        btnCloseNavbar.addEventListener('click', () => {
            menuNavbar.classList.remove('navbar-mobile-menu-content-active');
            menuNavbar.classList.add('navbar-mobile-menu-content');
            btnCloseNavbar.classList.remove('navbar-mobile-menu-active');
            btnCloseNavbar.classList.add('navbar-mobile-menu');
            btnOpenNavbar.classList.add('navbar-mobile-menu-active');
            btnOpenNavbar.classList.remove('navbar-mobile-menu');
        });
    }

};

export { navbarAction };