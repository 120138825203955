const keepFlashMessagesBesideNavBar = () => {
  document.addEventListener('scroll', (e) => {
    const flashMessage = document.querySelector('#flashes .alert');
    if (flashMessage) {
      if (window.pageYOffset < 80) {
        flashMessage.style.top = `${80 - window.pageYOffset}px`;
      } else {
        flashMessage.style.top = 0;
      }
    }
  });
}

export { keepFlashMessagesBesideNavBar };