const autoSubmitSearchForm = () => {
  var searchInput = document.getElementById("exhibitor_search_input");

  if (searchInput) {
    searchInput.addEventListener("input", function() {
      var csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

      // Serialize the form data
      var formData = new FormData(this.form);

      fetch(this.form.action, {
        method: this.form.method,
        headers: {
          'X-CSRF-Token': csrfToken,
          'X-Requested-With': 'XMLHttpRequest', 
        },
        body: new URLSearchParams(formData) 
      })
      .then(response => response.text())
      .then(data => {
        eval(data);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    });
  }
}

export { autoSubmitSearchForm };