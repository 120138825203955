// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// import { Application } from "@hotwired/stimulus"
// import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

// window.Stimulus = Application.start()
// const context = require.context("./controllers", true, /\.js$/)
// Stimulus.load(definitionsFromContext(context))

import Swal from 'sweetalert2/dist/sweetalert2.all.js'
import 'sweetalert2/src/sweetalert2.scss'
window.Swal = Swal;

import Rails from "@rails/ujs"
window.Rails = Rails;
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

require("@fortawesome/fontawesome-free/js/all")

import { infosPratiquesOnglets } from 'packs/infos-pratiques'
import { initSelectStands } from 'packs/init-select-stands'
import { modalAction } from 'packs/modals'
import { initUpdateCommandsPrices } from 'packs/init-update-commands-prices'
import { initFormValidation } from 'packs/init-form-validation'
import { navbarAction } from 'packs/navbar-mobile'
import { infosFormatMobile } from 'packs/infos-pratiques'
import { espaceUserMenuMobile } from 'packs/espace-user'
import { keepFlashMessagesBesideNavBar } from 'packs/flashes-position'
import { copyPasteContactDetails } from 'packs/registration-checkbox'
import { autoSubmitSearchForm } from 'packs/auto-submit-search-form'
import { makePlaceholdersDisappear } from 'packs/placeholder-handler'

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// External imports
import "bootstrap";

document.addEventListener('turbolinks:load', () => {
  infosPratiquesOnglets();
  initSelectStands();
  modalAction();
  initUpdateCommandsPrices();
  initFormValidation();
  navbarAction();
  infosFormatMobile();
  espaceUserMenuMobile();
  keepFlashMessagesBesideNavBar();
  copyPasteContactDetails();
  autoSubmitSearchForm();
  makePlaceholdersDisappear();
});
