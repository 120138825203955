const espaceUserMenuMobile = () => {
    const espaceUserTitles = document.querySelectorAll(".espace-user-mobile-menu-title");
    const espaceUserParts = document.querySelectorAll(".espace-user-mobile-part");
    const espaceUserReturnBtns = document.querySelectorAll(".espace-user-return-button");
    const espaceUserMenu = document.querySelector(".espace-user-mobile-menu")
    let index = 0;

    if (espaceUserTitles) {
        espaceUserTitles.forEach(espaceUserTitle => {
            espaceUserTitle.addEventListener('click', () => {
                index = espaceUserTitle.getAttribute('data-menu-user')
                espaceUserParts.forEach(espaceUserPart => {
                    if (espaceUserPart.getAttribute('data-menu-user') == index) {
                        espaceUserPart.classList.add('espace-user-mobile-active')
                        espaceUserPart.classList.remove('espace-user-mobile-inactive')
                        espaceUserMenu.classList.remove('espace-user-mobile-active')
                        espaceUserMenu.classList.add('espace-user-mobile-inactive')
                    }
                });
            });

        });
    }

    if (espaceUserReturnBtns) {
        espaceUserReturnBtns.forEach(espaceUserReturnBtn => {
            espaceUserReturnBtn.addEventListener('click', () => {
                espaceUserParts.forEach(espaceUserPart => {
                    if (espaceUserPart.classList.contains('espace-user-mobile-active')) {
                        espaceUserPart.classList.remove('espace-user-mobile-active')
                        espaceUserPart.classList.add('espace-user-mobile-inactive')
                    }
                    espaceUserMenu.classList.remove('espace-user-mobile-inactive')
                    espaceUserMenu.classList.add('espace-user-mobile-active')
                });
            });
        });

    }



}

export { espaceUserMenuMobile };