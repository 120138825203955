const initSelectStands = () => {
  window.makeStandSelectable = (stand) => {
    stand.addEventListener("click", (e) => {
      var standId = stand.dataset.standId;
      document.querySelector(`#selected_stands_stands_ids option[value='${standId}']`).setAttribute("selected", "selected");
      stand.style.border = "#C74827 3px solid";
      makeStandUnselectable(stand);
    });
  };

  window.makeStandUnselectable = (stand) => {
    stand.addEventListener("click", (e) => {
      var standId = stand.dataset.standId;
      document.querySelector(`#selected_stands_stands_ids option[value='${standId}']`).removeAttribute("selected");
      stand.style.border = "none";
      makeStandSelectable(stand);
    });
  };
};

export { initSelectStands };
